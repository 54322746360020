import React, { useState, useEffect } from 'react';
import { injectIntl } from 'react-intl';
import { Modal, Space, Radio } from 'antd';
import dayjs from 'dayjs';
import classnames from 'classnames';

import {
  DATE_FORMAT,
  NEXT_DAY_CASHOUT_FREQUENCIES,
  cashoutRadioOption
} from 'constants/wallet';
import { changeCashoutFrequency } from 'services/wallet';
import { isDatePassed } from 'utils/helpers';

import BRButton from 'components/BRButton/BRButton';
import { notify } from 'components/Notify/Notify';

import { ReactComponent as InfoIcon } from 'assets/bosta-icons/Info-Circle.svg';

import './ChangeCashoutModal.less';

const ChangeCashoutModal = ({
  intl,
  close,
  lastChangeDate,
  frequency,
  updateBusinessInfo,
  ...props
}) => {
  const [frequencyValue, setFrequencyValue] = useState(frequency);
  const [loading, setLoading] = useState(false);
  const [canChangeFrequency, setCanChangeFrequency] = useState(true);

  const formattedChangeDate = dayjs(lastChangeDate).format(DATE_FORMAT);
  const availableDate = dayjs(lastChangeDate).add(7, 'day');
  const formattedAvailableDate =
    lastChangeDate && dayjs(availableDate).format(DATE_FORMAT);

  const onChange = (e) => {
    setFrequencyValue(e.target.value);
  };

  const handleConfirmChange = async () => {
    if (frequencyValue === frequency) {
      close();
      return;
    }

    const payload = {
      paymentInfo: {
        paymentFrequency: frequencyValue
      }
    };
    setLoading(true);
    try {
      await changeCashoutFrequency(payload);
      notify({
        msg: intl.formatMessage({
          id: 'change_cashout_modal.success'
        }),
        type: 'success'
      });
      await updateBusinessInfo();
      setCanChangeFrequency(false);
      close();
    } catch (error) {
      notify({ msg: error.message, error });
    }
    setLoading(false);
  };

  const checkIfCanChangeFrequency = () => {
    if (lastChangeDate) {
      const condition = isDatePassed(availableDate, 'day');
      setCanChangeFrequency(condition);
    }
  };

  useEffect(() => {
    checkIfCanChangeFrequency();
  }, [lastChangeDate]);

  return (
    <Modal
      className="br-cashout__change-modal"
      title={intl.formatMessage({
        id: 'change_cashout_modal.header'
      })}
      loading={loading}
      onCancel={close}
      footer={
        <div className="br-chashout__change-modal-footer">
          <BRButton
            label={intl.formatMessage({
              id: canChangeFrequency
                ? 'common.cancel'
                : 'change_cashout_modal.got_it'
            })}
            onClick={close}
          />
          {canChangeFrequency && (
            <BRButton
              type="primary"
              label={intl.formatMessage({ id: 'common.confirm' })}
              onClick={handleConfirmChange}
              loading={loading}
            />
          )}
        </div>
      }
      {...props}
    >
      <div
        className={`br-cashout__change-info${
          canChangeFrequency ? '' : '-warning'
        }`}
      >
        <InfoIcon />
        {canChangeFrequency ? (
          <p>
            {intl.formatMessage({
              id: 'change_cashout_modal.info'
            })}
          </p>
        ) : (
          <div>
            <p className="body-medium">
              {intl.formatMessage(
                {
                  id: 'change_cashout_modal.info_warning'
                },
                {
                  date: formattedAvailableDate
                }
              )}
            </p>
            <p className="body">
              {intl.formatMessage(
                {
                  id: 'change_cashout_modal.info_warning_subtitle'
                },
                {
                  date: formattedChangeDate
                }
              )}
            </p>
          </div>
        )}
      </div>

      <Radio.Group
        className={classnames('br-cashout__change-radio-group', {
          'br-cashout__change-disabled': !canChangeFrequency
        })}
        onChange={onChange}
        value={frequencyValue}
      >
        <Space direction="vertical">
          {NEXT_DAY_CASHOUT_FREQUENCIES.map((item, index) => {
            const { value, label, subtitle, feeBadge, frequency, fees } =
              cashoutRadioOption(item);
            return (
              <Radio key={index} value={value} disabled={!canChangeFrequency}>
                {frequencyValue === value ? (
                  <div className="br-cashout__change-option-container">
                    <div className="br-cashout__change-option-info-p1">
                      <span>
                        <p className="body-medium">{label}</p>
                        <span>{subtitle}</span>
                      </span>
                      <p className="caption-medium br-cashout__daily-next-day-fee">
                        {feeBadge}
                      </p>
                    </div>
                    <div className="br-cashout__change-option-info-p2">
                      <div>
                        <p>
                          {intl.formatMessage({
                            id: 'change_cashout_modal.options.cashout_freq'
                          })}
                        </p>
                        <p className="br-cashout__daily-freq-value">
                          {frequency}
                        </p>
                      </div>
                      <div>
                        <p>
                          {intl.formatMessage({
                            id: 'change_cashout_modal.options.next_day_fees'
                          })}
                        </p>
                        <p>{fees}</p>
                      </div>
                    </div>
                  </div>
                ) : (
                  <p className="body-medium">{label}</p>
                )}
              </Radio>
            );
          })}
        </Space>
      </Radio.Group>
    </Modal>
  );
};

export default injectIntl(ChangeCashoutModal);
