import { fmt, intl } from 'components/IntlWrapper/IntlWrapper';
import { COUNTRY_FULLNAME } from './countries/constants/countries';
import { LOCALE } from './intl-wrapper';
import { URL_FLAGS } from './country-data';
import { getCurrency } from './countries/countries-mapping';

export const PAYMENT_TYPE = {
  POST_PAID: 'Postpaid',
  PRE_PAID: 'Prepaid'
};

export const CASHOUT_FREQUENCY = {
  WEEKLY: 'Weekly',
  DAILY: 'Daily',
  NEXT_DAY: 'Next Day'
};

export const PAYMENT_SCHEDULE = {
  DAILY: ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday'],
  WEEKLY_SUN: ['Sunday'],
  WEEKLY_MON: ['Monday'],
  WEEKLY_WED: ['Wednesday'],
  TWO_DAYS: ['Monday', 'Wednesday'],
  THREE_DAYS: ['Sunday', 'Tuesday', 'Thursday']
};

export const BALANCE_CATEGORIES = {
  CASH_COLLECTION_CYCLE: 0,
  BOSTA_FEES_CYCLE: 1,
  CASH_OUT: 2,
  COMPENSATION: 3,
  PACKING_MATERIAL: 4,
  TOP_UP: 5,
  REJECTED_CASHOUT: 8,
  BALANCE_ADJUSTMENT_POSITIVE: 9,
  BALANCE_ADJUSTMENT_NEGATIVE: 10,
  BORROW: 11,
  PICKUP_FEES: 13
};

export const FLYER_SIZE = {
  Normal: fmt({ id: 'wallet.cycles.flyer_size.normal' }),
  Large: fmt({ id: 'wallet.cycles.flyer_size.large' }),
  'X-Large': fmt({ id: 'wallet.cycles.flyer_size.x_large' }),
  'White Bag': fmt({ id: 'wallet.cycles.flyer_size.xx_large' }),
  Bulky: fmt({ id: 'wallet.cycles.flyer_size.bulky' }),
  'Light Bulky': fmt({ id: 'wallet.cycles.flyer_size.light_bulky' }),
  'Heavy Bulky': fmt({ id: 'wallet.cycles.flyer_size.heavy_bulky' })
};

export const PAYMENT_TRANSFER_METHOD = {
  FAWRY: 'Fawry',
  BANK_TRANSFER: 'Bank transfer',
  BANK_TRANSFER_CAP: 'Bank Transfer'
};

export const FILTERED_BALANCE_CATEGORIES = {
  'cash-collection': 0,
  'bosta-fees': 1
};

export const SORT_ORDER = {
  ascend: 'asc',
  descend: 'desc'
};

export const WALLET_VIDEO_URL = 'https://www.youtube.com/embed/A6lNH9zNDOk';
export const WALLET_VIDEO_URL_ARABIC =
  'https://www.youtube.com/embed/EIekRBhjW_A';

export const TABLE_PAGE_LIMIT = 50;
export const TABLE_PAGE_SIZE_OPTIONS = [50, 100, 200];

export const PERCENTAGE = 'PERCENTAGE';

export const ALL_PAID = 'All paid';

export const PROMOTION_SUBTYPES = [
  'PERCENTAGE_SALES',
  'CAPPED_VOLUME_PROMOTION'
];

export const TOP_UP_TRANSACTION_PAYMENT_TYPE = 'TOP_UP_WALLET';

export const BALANCE_ADJUSTMENT_CATEGORIES = {
  FULFILLMENT_SETTLEMENT: 'Fulfillment Settlement',
  COMPENSATION_VARIANCE: 'Compensation Variance',
  OVERDUE_SETTLEMENT: 'Overdue Settlement',
  COD_SETTLEMENT: 'COD Settlement',
  BORROW_ADJUSTMENT: 'Borrow Adjustment',
  EXTRA_FEES: 'Extra Fees',
  INTERNATIONAL_TRANSFER_FEES: 'International Transfer Fees',
  FULFILLMENT_PALLET_FEES: 'Fulfillment Pallet Fees',
  FULFILLMENT_STORAGE_FEES: 'Fulfillment Storage Fees',
  PICKUP_FEES_ADJUSTMENT: 'Pick-up Fees Adjustment'
};

export const BALANCE_ADJUSTMENT_MAPPING = {
  [BALANCE_ADJUSTMENT_CATEGORIES.FULFILLMENT_SETTLEMENT]:
    'fulfillment_settlement',
  [BALANCE_ADJUSTMENT_CATEGORIES.COMPENSATION_VARIANCE]:
    'compensation_variance',
  [BALANCE_ADJUSTMENT_CATEGORIES.OVERDUE_SETTLEMENT]: 'overdue_settlement',
  [BALANCE_ADJUSTMENT_CATEGORIES.COD_SETTLEMENT]: 'cod_settlement',
  [BALANCE_ADJUSTMENT_CATEGORIES.BORROW_ADJUSTMENT]: 'borrow_adjustment',
  [BALANCE_ADJUSTMENT_CATEGORIES.EXTRA_FEES]: 'extra_fees',
  [BALANCE_ADJUSTMENT_CATEGORIES.INTERNATIONAL_TRANSFER_FEES]:
    'international_transfer_fees',
  [BALANCE_ADJUSTMENT_CATEGORIES.FULFILLMENT_PALLET_FEES]:
    'fulfillment_pallet_fees',
  [BALANCE_ADJUSTMENT_CATEGORIES.FULFILLMENT_STORAGE_FEES]:
    'fulfillment_storage_fees',
  [BALANCE_ADJUSTMENT_CATEGORIES.PICKUP_FEES_ADJUSTMENT]:
    'pickup_fees_adjustment'
};

export const SUCCESS_PAYMENT_QUERY_PARAM = 'success';

export const DATE_FORMAT = 'DD MMMM YYYY';

export const NEXT_DAY_CASHOUT_FREQUENCIES = ['daily', 'weekly'];

export const cashoutRadioOption = (frequency) => {
  return {
    key: frequency,
    value: CASHOUT_LABELS[frequency].value,
    label: fmt({ id: `change_cashout_modal.options.${frequency}.label` }),
    subtitle: fmt({ id: `change_cashout_modal.options.${frequency}.subtitle` }),
    feeBadge: fmt({
      id: `change_cashout_modal.options.${frequency}.next_day_fee`
    }),
    frequency: fmt({
      id: `change_cashout_modal.options.${frequency}.frequency`
    }),
    fees: fmt({ id: `change_cashout_modal.options.${frequency}.fees` })
  };
};

export const CASHOUT_LABELS = {
  weekly: {
    label: fmt({ id: 'change_cashout_modal.options.weekly.label' }),
    value: 'Weekly'
  },
  daily: {
    label: fmt({ id: 'change_cashout_modal.options.daily.label' }),
    value: 'Next Day'
  },
  ['next day']: {
    label: fmt({ id: 'change_cashout_modal.options.daily.label' }),
    value: 'Next Day'
  }
};

export const renderCashoutTooltip = (frequency, daysSchedule) => {
  const { NEXT_DAY, DAILY } = CASHOUT_FREQUENCY;

  const tooltip = [NEXT_DAY, DAILY].includes(frequency)
    ? 'wallet.balance.header.cashout.cashout_frequency_daily_tooltip'
    : daysSchedule.length === 2
    ? 'wallet.balance.header.cashout.cashout_frequency_two_days_week_tooltip'
    : daysSchedule.length === 3
    ? 'wallet.balance.header.cashout.cashout_frequency_three_days_week_tooltip'
    : 'wallet.balance.header.cashout.cashout_frequency_weekly_wednesday_tooltip';

  return fmt({ id: tooltip });
};

export const EXCHANGE_COUNTRY_OPTIONS = {
  SA: 'sa',
  AE: 'ae'
};

export const EXCHANGE_CURRENCIES = {
  SAR: 'SAR',
  AED: 'AED'
};

export const INTERNATIONAL_COUNTRY_CURRENCY_ID = {
  EG: 'common.SAR_amount',
  AE: 'common.aed_amount'
};

export const CUSTOMS_VAT_TYPE = {
  DDU: 'DDU',
  DDP: 'DDP'
};

export const INTERNATIONAL_WALLET_VAT = {
  EG: 15,
  AE: 0
};

export const INTERNATIONAL_WALLET_BANK_KEYS = {
  EG: 'secondBankInfo',
  AE: 'bankInfo'
};
